define("discourse/plugins/discourse-private-inbox/discourse/initializers/apply-private-inbox", ["exports", "discourse/lib/plugin-api", "ember-addons/ember-computed-decorators"], function (_exports, _pluginApi, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePrivateInbox(api) {
    api.modifyClass("controller:user", dt7948.p({
      showPrivateMessages(viewingSelf, isAdmin) {
        if (!viewingSelf && this.siteSettings.private_inbox_enabled) {
          return false;
        } else {
          return this._super();
        }
      }
    }, [["method", "showPrivateMessages", [(0, _emberComputedDecorators.default)("viewingSelf", "currentUser.admin")]]]));
  }
  var _default = _exports.default = {
    name: "apply-private-inbox",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.8", initializePrivateInbox);
    }
  };
});